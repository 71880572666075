@import '../../../assets/_colors.scss';

.button {
  background-color: $dark-teal;
  border: none;
  outline:none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
  transition: 300ms;
}

.button--darkTheme {
  color: $light;
  background-color: $teal;
  padding: 10px;

  &.button--active {
    background-color: $light-teal;
  }

  &:hover {
    background-color: none;
    color: none;
  }
}

.button--lightTheme {
  color: $light-teal;
  background-color: $light;
  border: 2px solid $light-teal;

  &.button--active {
    background-color: $light-teal;
    color: $light;
  }

  &:hover {
    background-color: none;
    color: none;
  }
}

.button--darkTheme, .button--lightTheme {
  &:hover{
    background-color: none;
    color: none;
  }
}

.button--rounded {
  border-radius: 2px;
}

@media only screen and (min-width: 768px) {
  .button {
    font-size: 14px;
  }

  .button--darkTheme {
    &.button--active {
      background-color: $light-teal;
    }

    &:hover {
      background-color: $light-teal;
    }
  }

  .button--lightTheme {
    &.button--active, &:hover {
      background-color: $light-teal;
      color: $light;
    }
  }
}
