@import '../../../assets/_colors.scss';

.answerChoiceOutput {
  background-color: $light-teal;
  display: flex;
  padding: 16px;
}

.answerChoiceOutput__answerOutput {
  flex-grow: 1;
  padding: 8px;
  background-color: $light;
  border-radius: 5px;
}

.answerChoiceOutput__questionLabel {
  color: $teal;
  font-size: 14px;
  font-weight: 700;
}

.answerChoiceOutput__placeholder {
  color: $light-teal;
  font-weight: 800;
  margin: 8px 0;
}

.answerChoiceOutput__usersAnswer {
  font-weight: 800;
  font-size: 18px;
  color: $light-teal;
  margin-bottom: 8px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .answerChoiceOutput__usersAnswer {
    font-size: 24px;
  }
}
