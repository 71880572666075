@import '../../assets/dependencies.scss';

.Quiz {
  position: relative;
  max-width: 1080px;
  margin: 24px 8px;
  padding: 0.05px;
}

.Preferences {
  text-align: right;
  width: 100%;
  margin-top: 8px;
}

.Preferences p {
  font-size: 12px;
  color: #9e9e9e;
  font-weight: 500;
  margin-bottom: 8px;
}

.CorrectPopup {
  background-color: $light-teal;
  color: $light;
  fill: $light;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  visibility: hidden;
  p {
    font-weight: 600;
    font-size: 22px;
  }
  &.--show {
    @include zoomOutShuffleAnimation;

    visibility: visible;
  }
  & i {
    font-size: 72px;
    margin-left: 4px;
  }
}

.WrongPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  font-weight: 600;
  font-size: 72px;
  visibility: hidden;
  &.--show {
    @include pulsateAnimation;

    visibility: visible;
  }
  & i {
    color: red;
    margin-left: 4px;
  }
}

@media only screen and (min-width: 768px) {
  .Quiz {
    margin: 24px auto;
  }

  .Preferences p {
    font-size: 14px;
  }
}
