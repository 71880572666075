@import '../../../assets/_colors.scss';

.header {
  display: flex;
  justify-content: space-between;
  background-color: $light;
}

.header__navigation {
  display: flex;
  align-items: center;
}

.header__hamburger-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 8px 20px;

  i {
    color: $teal;
  }

  &:focus {
    outline: none;
  }
}
