@import '../../../assets/_colors.scss';

.quizOptions {
  border-bottom: 1px solid $lightest-teal;
  color: $light-teal;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
}

.quizOptions__quizLength {
  color: $light-teal;
  background-color: $light;
  border: 2px solid $light-teal;
  border-radius: 5px;
  font-size: 14px;
  margin-left: 12px;
  padding: 4px;
  & option {
    color: $light-teal;
  }
}