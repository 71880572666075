@import '../../../assets/_colors.scss';

.japaneseQuestion__answerField, .japaneseQuestion__emptyAnswer {
  box-sizing: border-box;
  color: $dark-teal;
  margin: 0 16px;
  font-size: 18px;
  padding: 4px 8px;
  width: 90%;
}

.japaneseQuestion__emptyAnswer {
  border: 2px solid red;
}

@media only screen and (min-width: 768px) {
  .japaneseQuestion__answerField {
    font-size: 16px;
    padding: 4px 8px;
  }
}