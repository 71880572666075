@import '../../../assets/_colors.scss';

.SideDrawer {
  z-index: 1000;
  position: absolute;
  width: 65%;
  max-width: 370px;
  height: 92%;
  background-color: $light-teal;
  color: $teal;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.DrawerOpen {
  transform: translateX(0);
}
