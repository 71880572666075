@import '../../../assets/_colors.scss';

.logo {
  color: $dark-teal;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -2px;
  opacity: 0.7;
  padding: 16px 8px 4px;
}

.logo__kana-text {
  color: $dark-teal;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 3px;
  line-height: 0.5;
}

@media only screen and (min-width: 768px) {
  .logo__text {
    font-size: 32px;
  }

  .logo__kana-text {
    font-size: 20px;
  }
}