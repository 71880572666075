@import '../../../assets/colors';


.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  transition: 250ms;
}

.backdrop--white {
  background-color: $light;
}

.backdrop--hidden {
  opacity: 0;
  // Just in case...
  z-index: -999;
}