@import '../../assets/_colors.scss';

.results {
  margin-top: 8px;
}

.results__scoreContainer {
  font-size: 32px;
  font-weight: 800;
  color: $light-teal;
  margin-bottom: 16px;
  text-align: center;
  width: 100%;
}

.results__scoreLabel {
  color: $light-teal;
  font-size: 24px;
  font-weight: 600;
}

.results__square {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 24px;
  border-bottom: 1px solid #cacaca;
}

.results__result {
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  flex: 0 0 125px;
  font-size: 16px;
  justify-content: center;
  text-align: center;
  margin-bottom: 8px;
  height: 109px;
  transition: opacity 300ms;
  & i {
    font-size: 40px;
  }
  &:hover {
    opacity: 0.4;
  }
}

.results__question {
  flex: 1 0;
}

.results__btnsContainer {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.results__result--correct {
  background-color: $light-teal;
}

.results__result--wrong {
  background-color: red;
}