@import '../../../assets/_colors.scss';

.topic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $light-teal;
  border-bottom: 1px $lightest-teal solid;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  padding: 16px;
  transition: 300ms;
  &:hover {
    background-color: $lightest-teal;
  }
}

.topic__quiz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $light-teal;
  background-color: $light;
  border-bottom: 1px $light-teal solid;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
  transition: 300ms;

  &:hover {
    background-color: $lightest-teal;
    color: $light;

    .topic__chevron--right {
      color: $light;
    }
  }
}

.topic__header {
  background-color: $teal;
  border-bottom: 1px solid $lightest-teal;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  padding: 16px;
}

.topic__chevron {
  font-size: 24px;
  transition: 300ms;
}

.topic__chevron--rotate {
  transform: rotate(180deg);
  transition: 300ms;
}

.topic__chevron--right {
  color: $light-teal;
  transition: 250ms;
}

.topic__quizzes {
  background-color: $light;
  overflow: hidden;
}
