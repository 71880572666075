@import '../../../assets/_colors.scss';

.question__buttonContainer {
  margin: 16px;
  margin-top: 32px;

  .question__nextBtn {
    font-size: 14px;
    padding: 12px 24px;
  }

  @media only screen and (min-width: 768px) {
    .question__nextBtn {
      font-size: 16px;
      padding: 16px 32px;
    }
  }
}
