@import '../../../assets/_colors.scss';

.Section {
  border-radius: 5px;
  background-color: $light;
}

.SectionHeader {
  padding: 16px;
  background: rgb(13,92,99);
  background: linear-gradient(90deg, rgba(13,92,99,1) 0%, rgba(36,123,123,1) 20%, rgba(120,205,215,1) 100%); border-radius: 5px 5px 0px 0px;
  font-size: 16px;
}


@media only screen and (min-width: 768px) {
  .SectionHeader {
    font-size: 24px;
  }
}