@import '../../../assets/_colors.scss';

.questionTitle {
  padding: 8px 16px;
}

.questionTitle__questionNumber {
  color: $teal;
  font-size: 14px;
  font-weight: 700;
}

.questionTitle__title {
  color: $dark-teal;
  font-weight: 800;
  font-size: 18px;
  margin-left: 8px;
}

.questionTitle__subtitle {
  display: inline-block;
  border-radius: 4px;
  background: $light-teal;
  padding: 5px 8px;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 16px;
}

@media only screen and (min-width: 768px) {
  .questionTitle__title {
    font-size: 24px;
  }

  .questionTitle__subtitle {
    font-size: 14px;
  }
}
