@import '../../../assets/_colors.scss';

.answerChoices {
  padding: 8px 16px;
}

.answerChoices__choiceLabel {
  color: $light-teal;
  font-weight: 600;
  margin-bottom: 8px;
}

.answerChoices__choiceBtn.button {
  padding: 16px;
  font-size: 16px;
  font-weight: 800;
  margin-right: 8px;
  margin-bottom: 8px;
}
