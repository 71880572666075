$teal: #247b7b;
$dark-teal: #0d5c63;
$light-teal: #44a1a0;
$lightest-teal: #78cdd7;
$light: #FCFCFC;
$light-grey: #ebebeb;
$mid-grey: #e2e1e1;
$dark-grey: #c8c8c8;
$background: $mid-grey;


// $teal: #1B3B6F;
// $dark-teal: #21295C;
// $grayish-teal: #9EB3C2;
// $light-teal: #065A82;
// $lightest-teal: #1C7293;
// $light: #fffffa;

// 02111b-3f4045-30292f-5d737e-fcfcfc