@import '../../../assets/_colors.scss';

.somaliQuestion__answerField, .somaliQuestion__emptyAnswer {
  color: $dark-teal;
  margin: 8px 0 16px;
  font-size: 18px;
  padding: 4px 8px;
}

.somaliQuestion__emptyAnswer {
  border: 2px solid red;
}

@media only screen and (min-width: 768px) {
  .somaliQuestion__answerField {
    font-size: 24px;
    padding: 4px 8px;
  }
}